/*eslint-disable */

import {bus} from '@/main';
import countries from '@/services/country.json';
import toast from '@/services/toast';

export default {
  name: 'profile-update-fields-popup',
  components: {},
  props: [],
  data () {
    return {
      country:'',
      city:'',
      height:'',
      date:'',
      blood_type:'',
      weight:'',
      submitted:false,
      countryList:[],
      country:'',
      city:'',
      address:'',
      submitted:false
    }
  },
  computed: {

  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    getSelfUserInfoAgainFun(){
      this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
        this.closeUpdateProfileInfoMethod();
      })
    },
    closeUpdateProfileInfoMethod() {
      bus.$emit('updatedProfilePopupBus', false)
      document.body.classList.remove('popup_open_body')
    },
    updateUserInfoFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            height: this.height,
            weight: this.weight,
            blood_type: this.blood_type,
            country:this.country,
            city:this.city,
            address:this.address
          }
         this.$store.dispatch('patientInfoUpdateAction', data).then((res) => {
          toast.success(res.data.msg);
          this.getSelfUserInfoAgainFun();
          this.submitted =  false
         }).catch((err) => {
          toast.error(err.data.msg);
          this.submitted =  false
         })
         return;
        }
      })
    },
  }
}


