/*eslint-disable */

import {bus} from '@/main';
import countries from '@/services/country.json';
import {
  mapGetters
} from 'vuex';
import updateProfileInfo from '@/components/private/profile-update-fields-popup/index.vue';

export default {
  name: 'payment-checkout',
  components: {
    'update-profile-popup' : updateProfileInfo,
  },
  props: [],
  data () {
    return {
      appointmentInfo:null,
      countryList:[],
      doctorInfo:null,
      selfUserId:'',
      isSubmitted:false,
      domainLocationOrigin:'',
      appointmentId:'',
      updatedProfilePopup:false,
      selfUserInfo:null
    }
  },
  computed: {
    ...mapGetters([
      'appointmentDetailGetetrs'
    ]),
    getAppointmentDetailsFun(){
      return this.appointmentInfo = this.$store.getters.appointmentDetailGetetrs.data
    },
    setInitialStateFun(){
      if(this.appointmentInfo){
        this.doctorInfo = this.appointmentInfo.doctor_detail
      }
      return
    }
  },
  mounted () {
    this.$store.dispatch('getSelfUserInfoApiFun').then((res)=>{
      this.selfUserInfo = res.data.data.profile
      if(this.selfUserInfo.city || this.selfUserInfo.country || this.selfUserInfo.address) return;
      this.updatedProfilePopup = true
      document.body.classList.add('popup_open_body')
    })
    this.domainLocationOrigin = window.location.origin
    this.appointmentId = this.$route.params.appointmentId
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    bus.$on('userUpdateStatus', (data) => {
    });
    this.getAppointmentDetailsFun
    this.setInitialStateFun
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    bus.$on('updatedProfilePopupBus', (data) => {
      this.updatedProfilePopup = data
    });
  },
  methods: {
    getCountryNameFun(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    appointmentTabFun(data){
      this.appintmentDetailsTab = data
      if(data != 'chatWithDoctor'){
        bus.$emit('socketClosedNotifications', true);
      }
    },
    consultWorkspaceTabsActvmethod(status){
      this.consultWorkspaceTabs = status
    },
    payDoctorConsultFeesMethod() {
      this.isSubmitted = true
      let formData = {
        doctor_id: this.doctorInfo.user,
        appointment_id: this.appointmentId,
        patient_id: this.selfUserId,
        datetime : new Date(),
        doctor_first_name:this.doctorInfo.first_name,
        doctor_last_name:this.doctorInfo.last_name,
        amount: this.doctorInfo.amount,
        currency_code: this.doctorInfo.currency,
        return_url: this.domainLocationOrigin + '/payment/success/',
        cancel_url:this.domainLocationOrigin + '/payment/failed/',
      }
      let data = {
        payment_in_progress:true
      }
      this.$store.dispatch('updateAppointmentDetailsApiFun', {data:{data:data, id:this.appointmentId}})
      this.$store.dispatch('doctorConsultFeesFunc', formData).then((res) => {
        let linkOfPayPal =  res.data.links[1].href
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = linkOfPayPal;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.isSubmitted = false
        }, 100);
      }).catch((err) => {
        this.isSubmitted = false
      })
    },
  },
  watch:{
    getAppointmentDetailsFun(){
      return this.$store.getters.appointmentDetailGetetrs
    },
    setInitialStateFun(){
      if(this.appointmentInfo){
        this.doctorInfo = this.appointmentInfo.doctor_detail
      }
      return
    }
  },
  beforeRouteLeave: function(to, from, next) {
    bus.$emit('socketClosedNotifications', true);
    next()
  }
}